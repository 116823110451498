import React, { Component } from "react";
import Axios from "axios";

import { Link } from "react-router-dom";

export class BlogHome extends Component {
	state = {
		post: [],
	};

	mediumURL =
		"https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@Recoverysol";

	componentDidMount() {
		Axios.get(this.mediumURL)
			.then((data) => {
				console.log(data);
				const res = data.data.items.filter(
					(item) => item.categories.length < 19
				);
				this.setState({
					post: res,
				});
			})
			.catch((e) => {
				this.setState({ error: e.toJSON() });
				console.log(e);
			});
	}

	render() {
		const renderPostItem = (index) => {
			const item = this.state.post[index];
			if (!item) return null;

			const imgurl = item.description;
			const match =
				typeof imgurl === "string" && imgurl.match(/<img[^>]+src="([^">]+)"/);
			const imageSrc = match ? match[1] : null;

			return (
				<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
					<div className="im_box">
						<div className="thumbnail">
							<Link to="/blog-details">
								<img
									className="blog_width"
									src={imageSrc || item.thumbnail}
									alt="Blog Images"
								/>
							</Link>
						</div>
						<div className="content">
							<div className="inner">
								<div className="content_heading">
									<h4 className="title">
										<Link to={`/blog/${item.title}`}>{item.title}</Link>
									</h4>
								</div>
								<div className="content_footer">
									<Link
										to={`/blog/${item.title}`}
										className="rn-btn btn-opacity"
									>
										Read More
									</Link>
								</div>
							</div>
							<Link
								className="transparent_link"
								to={`/blog/${item.title}`}
							></Link>
						</div>
					</div>
				</div>
			);
		};

		return (
			<>
				<section className="appie-blog-area pt-90 pb-95">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="appie-section-title text-center">
									<h3 className="appie-title">Popular posts</h3>
									<p>Different layouts and styles for team sections.</p>
								</div>
							</div>
						</div>
						<div className="row">
							{renderPostItem(0)}
							{renderPostItem(1)}
							{renderPostItem(2)}
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default BlogHome;
