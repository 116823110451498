import React, { Component } from "react";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { FiCheck } from "react-icons/fi";

class Accordion01 extends Component {
	render() {
		return (
			<Accordion className="accodion-style--1" preExpanded={"0"}>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							Review and examine your case.
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						By conducting our thorough preliminary checks, we are able to assess
						the potential for a substantial recovery in your case with our vast
						experience. Our goal is to ensure that we provide the best possible
						outcome for you.
					</AccordionItemPanel>
				</AccordionItem>

				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							Identify and collect evidence
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						To succeed, we must analyze the environment, gather relevant data
						and evidence, and make informed decisions with confidence.
					</AccordionItemPanel>
				</AccordionItem>

				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>Confront the entities</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p>
							We have the ability to freeze all accounts and assets of those who
							illegally transferred your asset and hold them accountable for
							their actions.
						</p>
					</AccordionItemPanel>
				</AccordionItem>

				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>Recover your assets</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						Rest assured that we are committed to customer satisfaction and will
						leave no stone unturned to ensure you receive a full refund
					</AccordionItemPanel>
				</AccordionItem>
			</Accordion>
		);
	}
}

export default Accordion01;
