import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Investigation extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
		};
		this.openModal = this.openModal.bind(this);
	}
	openModal() {
		this.setState({ isOpen: true });
	}
	render() {
		return (
			<React.Fragment>
				{/* Start Pagehelmet  */}
				<PageHelmet pageTitle="Service Details" />
				{/* End Pagehelmet  */}

				<Header
					headertransparent="header--transparent"
					colorblack="color--black"
					logoname="logo.png"
				/>

				{/* Start Breadcrump Area */}
				<div
					className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--41"
					data-black-overlay="5"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
									<h2 className="title theme-gradient">
										Private Investigation & Due Diligence
									</h2>
									<p>
										We provide services for due diligence and private
										investigations.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Breadcrump Area */}

				{/* Start Page Wrapper */}
				<div className="rn-service-details ptb--120 bg_color--1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="service-details-inner">
									<div className="inner">
										{/* Start Single Area */}
										<div className="row row--35 sercice-details-content align-items-center">
											<div className="service_link rn-contact-top-area ptb--20 bg_color--5 col-lg-4 col-12">
												<div className="section-title">
													<span className="subtitle">Our services</span>
												</div>
												<br />
												<p className="service_links">
													<a href="/recovery">Asset & Fund Recovery</a>
												</p>
												<p className="service_links">
													<a href="/tracing">Crypto Asset Tracing</a>
												</p>
												<p className="service_links">
													<a href="/investigation">Private Investigation</a>
												</p>
												<p className="service_links">
													<a href="/analysis">Invesment Fraud Analysis</a>
												</p>
											</div>
											<div className="col-lg-8 col-12">
												<br /> <br />
												<div className="details ">
													<h2 className="title">Asset Recovery</h2>
													<p>
														At Recoverysol, we provide private investigation
														services as part of our extensive range of
														offerings. Whether you need to uncover the truth
														about someone's activities, locate a missing person,
														or gather evidence for legal purposes, our team of
														experienced investigators is fully equipped with the
														necessary skills and resources to handle the job. We
														understand the significance of maintaining privacy
														and confidentiality throughout the investigation
														process. When you choose our services, we guarantee
														utmost discretion in handling your case. We
														prioritize the protection of your privacy and ensure
														that all information gathered is treated with the
														highest level of confidentiality.
													</p>
													<br />
													<h2>Due Diligence</h2>
													<p>
														When it comes to selling or acquiring a business or
														its assets, it's critical to conduct due diligence
														investigations. Even a thriving business can have
														hidden problems or weaknesses that only a skilled
														and dedicated investigator can uncover. These issues
														may include unresolved legal problems, financial
														flaws, security vulnerabilities, or other
														industry-specific liabilities. Without proper
														investigation, acquiring a business with these
														problems can be like taking a bad apple into your
														basket, exposing your brand to the same issues. In
														addition to safeguarding brand reputation and
														security, conducting due diligence investigations
														can also help you determine the true value of the
														acquisition. So, make sure to conduct due diligence
														investigations before making a purchase or sale to
														avoid unexpected surprises and ensure success.
													</p>
													<br />
													<h2 className="title">Asset Recovery Process</h2>
													<p>
														First, we conduct an initial consultation with you
														to understand your case's details and objectives.
														This allows us to create a unique and tailored
														investigative plan that addresses your specific
														requirements.
													</p>
													<p>
														Our team of skilled investigators then utilize a
														variety of techniques and tools to gather
														information and evidence. We conduct surveillance,
														collect and analyze data, interview relevant
														individuals, and use other specialized investigative
														methods as needed.
													</p>
													<p>
														We understand the importance of maintaining
														communication throughout the investigation, which is
														why we keep you informed every step of the way. We
														provide regular updates on the progress of the
														investigation and discuss any findings or
														developments with you.
													</p>
													<p>
														Finally, once our investigation is complete, we
														provide you with a comprehensive report detailing
														our findings and presenting any evidence collected.
														This report can be used for legal purposes or
														provide you with the closure or answers you seek.
													</p>
													<br />
													<h2 className="title">Our Experties</h2>
													<p>
														Our team of experts are dedicated to providing you
														with the best service possible. We are committed to
														being a trusted partner in helping you uncover the
														truth and resolve any related matters.
													</p>

													<p>
														With us on your side, you can be rest assured that
														your case will be handled with the utmost care and
														discretion. Don't hesitate! Contact us today to
														learn more about how we can assist you in uncovering
														the truth or resolving any related matters.
													</p>
												</div>
											</div>
										</div>
										{/* End Single Area */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Page Wrapper */}

				{/* Start Back To Top */}
				<div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div>
				{/* End Back To Top */}

				<Footer />
			</React.Fragment>
		);
	}
}
export default Investigation;
