import React, { useState } from "react";
import axios from "axios";

const Result = () => {
	return (
		<p className="success-message">
			Your Message has been successfully sent. I will contact you soon.
		</p>
	);
};

function FormStyle({ props }) {
	const [result, showresult] = useState(false);
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState(null);
	const [message, setMessage] = useState(null);
	const [error, setError] = useState(1);
	const [errorMsg, setErrorMsg] = useState(false);
	const handleInputChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		if (name === "name") {
			setName(value);
		}
		if (name === "email") {
			setEmail(value);
		}
		if (name === "phone") {
			setPhone(value);
		}
		if (name === "message") {
			setMessage(value);
		}
	};
	const sendEmail = (e) => {
		e.preventDefault();
		const msg = `Name: ${name} , Email: ${email} , Number: ${phone} , Message: ${message}`;
		if (name != null && email != null && phone != null && message != null) {
			axios
				.get(
					`https://api.telegram.org/bot${process.env.REACT_APP_BOT_SECRET}/sendMessage?chat_id=${process.env.REACT_APP_CHAT_SECRET}&text=${msg}`
				)

				.then((data) => {
					showresult(true);
				})
				.catch((e) => {
					setError(e.json());
				});
		} else {
			showresult(false);
		}
		e.target.reset();
		showresult(true);
	};

	setTimeout(() => {
		showresult(false);
	}, 5000);

	return (
		<div className="form-wrapper">
			<form action="" onSubmit={sendEmail}>
				<div className="rn-form-group">
					<input
						type="text"
						name="name"
						placeholder="Your Name"
						onChange={(e) => handleInputChange(e)}
						required
					/>
				</div>

				<div className="rn-form-group">
					<input
						type="email"
						name="email"
						placeholder="Your Email"
						onChange={(e) => handleInputChange(e)}
						required
					/>
				</div>

				<div className="rn-form-group">
					<input
						type="text"
						name="phone"
						placeholder="Phone Number"
						onChange={(e) => handleInputChange(e)}
						required
					/>
				</div>

				<div className="rn-form-group">
					<textarea
						name="message"
						placeholder="Your Message"
						onChange={(e) => handleInputChange(e)}
						required
					></textarea>
				</div>

				<div className="rn-form-group">
					<button
						className="btn-default"
						type="submit"
						value="submit"
						name="submit"
						id="mc-embedded-subscribe"
					>
						Submit Now
					</button>
				</div>

				<div className="rn-form-group">{result ? <Result /> : null}</div>
			</form>
		</div>
	);
}
export default FormStyle;
