import React, { Component } from "react";
import { FiSend } from "react-icons/fi";

import { Link } from "react-router-dom";
class About extends Component {
	render() {
		let title = "About Recoverysol",
			description =
				"At Recoverysol Cyber Investigations, our mission is simple - to protect and serve our clients by utilizing the latest technology and techniques to bring justice to those who have been scammed. We understand the frustration and devastation that comes with falling victim to online scams, which is why we are dedicated to fighting back. With one of the highest success rates in the industry, we are confident in our abilities to help you recover what is rightfully yours.Our team of Certified Cyber Investigators are experts in their field and continuously stay updated on the ever-evolving world of cybercrime. We pride ourselves on our no-nonsense approach to confronting scammers and holding them accountable for their actions. Our open-source intelligence methods allow us to gather valuable information and trace assets, including cryptocurrency, to ensure a successful recovery for our clients.It all began when our founder, a victim of an online scam himself, realized that there was a need for a proactive and aggressive approach to combatting cybercrime. ",
			description2 =
				"That's when Recoverysol was born - with the goal of providing top-notch cyber investigation services while also bringing awareness about online scams and how to avoid them.At Recoverysol Cyber Investigations, we are more than just a company - we are a team on a mission to protect innocent individuals from financial. we have our headquater in New york and our operation centers in different countries";

		return (
			<React.Fragment>
				<div className="about-wrapper">
					<div className="container">
						<div className="row row--35 align-items-center">
							<div className="col-lg-5 col-md-12">
								<div className="thumbnail">
									<img
										className="w-100"
										src="/assets/images/about/about-1.png"
										alt="About Images"
									/>
								</div>
							</div>
							<div className="col-lg-7 col-md-12">
								<div className="about-inner inner">
									<div className="section-title">
										<div className="icon">
											<FiSend />
										</div>
										<h2 className="title">{title}</h2>
										<p className="description">{description}</p>
										<p className="description">{description2}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default About;
