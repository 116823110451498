import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Tracing extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
		};
		this.openModal = this.openModal.bind(this);
	}
	openModal() {
		this.setState({ isOpen: true });
	}
	render() {
		return (
			<React.Fragment>
				{/* Start Pagehelmet  */}
				<PageHelmet pageTitle="Service Details" />
				{/* End Pagehelmet  */}

				<Header
					headertransparent="header--transparent"
					colorblack="color--black"
					logoname="logo.png"
				/>

				{/* Start Breadcrump Area */}
				<div
					className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--40"
					data-black-overlay="5"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
									<h2 className="title theme-gradient">Crypto Asset Tracing</h2>
									<p>
										We specialize in recovering lost virtual assets. Our experts
										use advanced technology and blockchain analysis to quickly
										locate and retrieve your assets.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Breadcrump Area */}

				{/* Start Page Wrapper */}
				<div className="rn-service-details ptb--120 bg_color--1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="service-details-inner">
									<div className="inner">
										{/* Start Single Area */}
										<div className="row row--35 sercice-details-content align-items-center">
											<div className="service_link rn-contact-top-area ptb--20 bg_color--5 col-lg-4 col-12">
												<div className="section-title">
													<span className="subtitle">Our services</span>
												</div>
												<br />
												<p className="service_links">
													<a href="/recovery">Asset & Fund Recovery</a>
												</p>
												<p className="service_links">
													<a href="/tracing">Crypto Asset Tracing</a>
												</p>
												<p className="service_links">
													<a href="/investigation">Private Investigation</a>
												</p>
												<p className="service_links">
													<a href="/analysis">Invesment Fraud Analysis</a>
												</p>
											</div>
											<div className="col-lg-8 col-12">
												<br /> <br />
												<div className="details ">
													<h2 className="title">Cryptocurrencies</h2>
													<p className="description">
														Cryptocurrencies are the new digital assets that
														have revolutionized the monetary system. They offer
														a decentralized system, free from the restrictions
														of regulatory authorities. The question arises, "Are
														cryptocurrencies traceable?" The answer is yes, with
														a few exceptions. The majority of virtual currencies
														are traceable. Every cryptocurrency transaction is
														recorded in a digital wallet, which is then stored
														in the Blockchain.
													</p>
													<p className="description">
														We have a technical solution that allows us to trace
														the movement of cryptocurrencies. We can identify
														specific wallets, determine their location, and
														track the access of those who use them. It's
														important to note that not all cryptocurrencies are
														traceable. However, we can trace the most popular
														cryptocurrencies like Bitcoin and Ethereum, among
														others. Invest in cryptocurrencies with confidence,
														knowing that our technical solution can provide you
														with secure and transparent transactions.
													</p>
													<br />
													<h2 className="title">
														Cryptocurency Tracing Process
													</h2>
													<p>
														We use advanced tracing software to follow funds
														through a complex web of online transactions,
														detecting suspicious trends and uncovering key
														associations with criminal operations. This
														meticulous process demands considerable time,
														resources, and expertise, but we're committed to
														helping you recover your assets.
													</p>
													<p>
														Once we've pinpointed the wallet address, we work to
														identify its owner. While some exchanges will share
														the wallet holder's identification upon request,
														most require a request from the police. That's why
														we can provide a draft request letter to make the
														process as effortless as possible for the police.
														While police responses can differ by jurisdiction,
														station, and officer, we're here to support their
														efforts to recover your assets.
													</p>
													<p>
														Our reports are prepared by a Cryptocurrency Tracing
														Certified Examiner (CTCE) and detail lawful evidence
														for civil and criminal prosecutions. With our
														assistance, the police will be equipped to recover
														your assets, and you'll be best positioned to get
														your money back. Don't let cryptocurrency scammers
														get away with your hard-earned money. Contact us
														today to begin the process of crypto scam recovery.
													</p>
													<br />
													<h2 className="title">Our Experties</h2>
													<p>
														With Recoverysol's sophisticated intelligence and
														blockchain analytics tools, you can now track down
														over 800 cryptocurrencies on the blockchain. This
														powerful software is favored by international law
														enforcement agencies and can reveal the Virtual
														Asset Service Provider (VASP) used to manage coins.
													</p>
													<p>
														Thanks to Recoverysol's team of
														investigators, analysts, and researchers, as well as
														its extensive relationships with state, federal, and
														international partner agencies around the world, you
														can trace money trails for crypto scam recovery
														efforts in any country. Recoverysol's invaluable
														connections in the cryptocurrency fraud industry
														include a network of confidential informants,
														sources, and whistleblowers, as well as expert
														witnesses in cybercrime and cyber forensics. Should
														you require it, we can also refer you to a law
														enforcement agency.
													</p>
													<p>
														At Recoverysol, we understand the importance of
														client confidentiality. That's why we perform every
														crypto tracing investigation with total discretion
														and a commitment to client privacy. We encrypt all
														personal data to protect your privacy and will never
														disclose case details without consent. Trust
														Recoverysol for complete client confidentiality.
													</p>
												</div>
											</div>
										</div>
										{/* End Single Area */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Page Wrapper */}

				{/* Start Back To Top */}
				{/* <div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div> */}
				{/* End Back To Top */}

				<Footer />
			</React.Fragment>
		);
	}
}
export default Tracing;
