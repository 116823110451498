import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMapReact from "google-map-react";
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
	static defaultProps = {
		center: {
			lat: 59.95,
			lng: 30.33,
		},
		zoom: 11,
	};

	render() {
		return (
			<React.Fragment>
				<PageHelmet pageTitle="Contact" />

				<Header
					headertransparent="header--transparent"
					colorblack="color--black"
					logoname="logo.png"
				/>

				{/* Start Breadcrump Area */}
				<div
					className="rn-page-title-area pt--120 pb--190 bg_image bg_image--15"
					data-black-overlay="6"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="rn-page-title text-center pt--100">
									<h2 className="title theme-gradient">Contact Us</h2>
									<p>
										Get in touch and our team of experts will work to get your
										money back
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Breadcrump Area */}

				{/* Start Contact Top Area  */}
				<div className="rn-contact-top-area ptb--120 bg_color--5">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title mb--30 text-center">
									<span className="subtitle">Our contact address</span>
									<h2 className="title">Quick Contact Address</h2>
									<p className="description">
										Feel free to contact us directly or leave us a message in
										the form to the right and we will get back to you within 24
										business hours.
									</p>
								</div>
							</div>
						</div>
						<div className="row">
							{/* Start Single Address  */}
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="rn-address">
									<div className="icon">
										<FiHeadphones />
									</div>
									<div className="inner">
										<h4 className="title">Contact Phone Number</h4>
										<p>
											<a href="tel:+17062091310">+1 (706) 209 1310</a>
										</p>
										<p>
											<a href="tel:+97257106781">+972 5 710 6781 </a>
										</p>
									</div>
								</div>
							</div>
							{/* End Single Address  */}

							{/* Start Single Address  */}
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="rn-address">
									<div className="icon">
										<FiMail />
									</div>
									<div className="inner">
										<h4 className="title">Our Email Address</h4>
										<p>
											<a href="mailto:contact@grecoverysol.org">
												contact@recoverysol.org
											</a>
										</p>
										<p>
											<a href="mailto:support@grecoverysol.org">
												legal@recoverysol.org
											</a>
										</p>
									</div>
								</div>
							</div>
							{/* End Single Address  */}

							{/* Start Single Address  */}
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="rn-address">
									<div className="icon">
										<FiMapPin />
									</div>
									<div className="inner">
										<h4 className="title">Our Location</h4>
										<p>
											795 East Mayflower St. <br /> Troy, NY 12180
										</p>
									</div>
								</div>
							</div>
							{/* End Single Address  */}
						</div>
					</div>
				</div>
				{/* End Contact Top Area  */}

				{/* Start Contact Page Area  */}
				<div className="rn-contact-page ptb--120 bg_color--1">
					<ContactTwo />
				</div>
				{/* End Contact Page Area  */}

				{/* Start Brand Area */}
				<div className="rn-brand-area bg_color--5 ptb--60">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-12 section-title text-center mb--30 mb_sm--0">
								<span className="subtitle">Scams We've Helped With.</span>
								<BrandTwo />
							</div>
						</div>
					</div>
				</div>
				{/* End Brand Area */}

				{/* Start Back To Top */}
				{/* <div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div> */}
				{/* End Back To Top */}

				<Footer />
			</React.Fragment>
		);
	}
}
export default Contact;
