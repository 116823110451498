import React, { Component } from "react";
import FormStyle from "./FormStyle";

class ContactTwo extends Component {
	render() {
		return (
			<div className="contact-form--1">
				<div className="container">
					<div className="row row--35 align-items-start">
						<div className="col-lg-6 order-2 order-lg-1">
							<div className="section-title text-left mb--50">
								<span className="subtitle">Get To Us</span>
								<h2 className="title">Fill The Form Bellow.</h2>
							</div>
							<FormStyle />
						</div>
						<div className="col-lg-6 order-1 order-lg-2">
							<div className="thumbnail mb_md--30 mb_sm--30">
								<img src="/assets/images/about/about-12.jpg" alt="trydo" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default ContactTwo;
