import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import AboutComp from "../component/HomeLayout/homeOne/About";

class About extends Component {
	render() {
		return (
			<React.Fragment>
				<PageHelmet pageTitle="About" />

				<Header
					headertransparent="header--transparent"
					colorblack="color--black"
					logoname="logo.png"
				/>
				{/* Start Breadcrump Area */}
				<Breadcrumb title={"About"} />
				{/* End Breadcrump Area */}

				{/* Start About Area */}
				<div className="about-area ptb--120 bg_color--1">
					<AboutComp />
				</div>
				{/* End About Area */}

				{/* Start CounterUp Area */}
				<div className="rn-counterup-area ptb--120 bg_color--5">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center">
									<span className="subtitle">Experts growts</span>
									<h2 className="title">Our Company Growth</h2>
									<p className="description">
										We have grown strength over the past 20 years.
									</p>
								</div>
							</div>
						</div>
						<CounterOne />
					</div>
				</div>
				{/* End CounterUp Area */}

				{/* Start Team Area  */}
				<div className="rn-team-area bg_color--1 ptb--120">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center mb--25">
									<span className="subtitle">Skilled team</span>
									<h2 className="title">Skilled Team</h2>
									<p className="description">
										Our team at KTB is comprised of dedicated professionals with
										diverse backgrounds and expertise. From Customer Support
										specialists to Lawyers & Forensic Accountants, each member
										of our team plays a crucial role in helping our clients
										recover their funds from scammers. We work together
										seamlessly to ensure that our clients receive the support
										and guidance they need throughout the recovery process.
										<br />
										Our team of cyber investigators is certified in the United
										States and has extensive experience with investigations
										spanning six continents.They are dedicated to providing the
										best possible outcomes for our clients.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Team Area  */}

				{/* Start Testimonial Area */}
				<div className="rn-testimonial-area bg_color--5 ptb--120">
					<div className="container">
						<Testimonial />
					</div>
				</div>
				{/* End Testimonial Area */}

				{/* Start Finding Us Area  */}
				<div
					className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--18"
					data-black-overlay="5"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-8 offset-lg-2">
								<div className="inner">
									<div className="content-wrapper">
										<div className="content">
											<h4 className="theme-gradient">Mission</h4>
											<p>
												Our mission at Recoverysol is to provide top-notch cyber
												investigation services to our clients, ensuring that
												their assets are protected and recovered from scammers
												and cyber criminals.
											</p>
											<h4 className="theme-gradient">Vision</h4>
											<p>
												Our vision at Recoverysol is to become a global leader
												in cyber security and asset recovery, setting the
												standard for excellence in the industry.
											</p>
											<a className="btn-default" href="/contact">
												Get Started
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Finding Us Area  */}
				{/* Start Brand Area */}
				<div className="rn-brand-area bg_color--1 ptb--120">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-12">
								<div className="section-title text-center mb--30">
									<span className="subtitle">Top Scams</span>
									<h2 className="title">Scams We've Helped Our Client With.</h2>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<BrandTwo />
							</div>
						</div>
					</div>
				</div>
				{/* End Brand Area */}

				{/* Start Back To Top */}
				<div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div>
				{/* End Back To Top */}

				<Footer />
			</React.Fragment>
		);
	}
}
export default About;
