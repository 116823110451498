import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

class Header extends Component {
	constructor(props) {
		super(props);
		this.menuTrigger = this.menuTrigger.bind(this);
		this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
		//  this.subMetuTrigger = this.subMetuTrigger.bind(this);
		window.addEventListener("load", function () {
			console.log("All assets are loaded");
		});
	}

	menuTrigger() {
		document.querySelector(".header-wrapper").classList.toggle("menu-open");
	}
	CLoseMenuTrigger() {
		document.querySelector(".header-wrapper").classList.remove("menu-open");
	}
	render() {
		var elements = document.querySelectorAll(".has-droupdown > a");
		for (var i in elements) {
			if (elements.hasOwnProperty(i)) {
				elements[i].onclick = function () {
					this.parentElement
						.querySelector(".submenu")
						.classList.toggle("active");
					this.classList.toggle("open");
				};
			}
		}

		const { logo, color = "default-color" } = this.props;
		let logoUrl;
		if (logo === "light") {
			logoUrl = <img src="/rs_w.png" alt="Digital Agency" />;
		} else {
			logoUrl = <img src="/rs_w.png" alt="Digital Agency" />;
		}

		return (
			<header
				className={`header-area header--fixed formobile-menu header--transparent ${color}`}
			>
				<div className="header-wrapper" id="header-wrapper">
					<div className="header-left">
						<div className="logo">
							<a href="/">
								{logoUrl}
								<span style={{ color: "white", marginLeft: "-5px" }}>
									Recoverysol
								</span>
							</a>
						</div>
					</div>
					<div className="header-right">
						<nav className="mainmenunav d-lg-block">
							<ul className="mainmenu">
								<li>
									<Link to="/">Home</Link>
								</li>
								<li className="has-droupdown">
									<Link to="/service">Service</Link>
									<ul className="submenu">
										<li>
											<Link to="/recovery">Asset & Fund Recovery</Link>
										</li>
										<li>
											<Link to="/tracing">Crypto Asset Tracing</Link>
										</li>
										<li>
											<Link to="/investigation">Private Investigation</Link>
										</li>
										<li>
											<Link to="/analysis">Invesment Fraud Analysis</Link>
										</li>
									</ul>
								</li>
								<li>
									<Link to="/about">About</Link>
								</li>
								<li>
									<Link to="/blog">Blog</Link>
								</li>
							</ul>
						</nav>
						<div className="header-btn">
							<Link
								className="btn-default btn-border btn-opacity"
								to="/contact"
							>
								Contact Us
							</Link>
						</div>
						{/* Start Humberger Menu  */}
						<div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
							<span
								onClick={this.menuTrigger}
								className="menutrigger text-white"
							>
								<FiMenu />
							</span>
						</div>
						{/* End Humberger Menu  */}
						<div className="close-menu d-block d-lg-none">
							<span onClick={this.CLoseMenuTrigger} className="closeTrigger">
								<FiX />
							</span>
						</div>
					</div>
				</div>
			</header>
		);
	}
}
export default Header;
