import React, { Component, Fragment } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Testimonial from "../elements/Testimonial";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Header from "../component/header/Header";
import useScript from "../Script";
import CallAction from "../elements/callaction/CallAction";
import Team from "../elements/Team";
import Accordion01 from "../elements/Accordion";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import CounterOne from "../elements/counters/CounterOne";
import Footer from "../component/footer/Footer";
import BlogHome from "../elements/Bloghome";

const SlideList = [
	{
		textPosition: "text-left",
		bgImage: "bg_image--18",
		category: "",
		title: "Crypto Tracing",
		description:
			"We utilize state-of-the-art technology and experienced blockchain analysts with years of cryptocurrency expertise to recover any misappropriated virtual assets.",
		buttonText: "Contact Us",
		buttonLink: "/contact",
	},
	{
		textPosition: "text-center",
		bgImage: "bg_image--19",
		category: "",
		title: "Asset Recovery",
		description:
			"We provide a comprehensive solution for locating lost or stolen assets by combining cyber intelligence, investigations, and on-the-ground monitoring.",
		buttonText: "Contact Us",
		buttonLink: "/contact",
	},
	{
		textPosition: "text-left",
		bgImage: "bg_image--33",
		category: "",
		title: "Cyber Investigation",
		description:
			"Our team investigates identity theft and fraud in cybercrime by identifying suspects, monitoring their activities, and collecting evidence to build a case against them.",
		buttonText: "Contact Us",
		buttonLink: "/contact",
	},
];

const ScriptLoader = () => {
	// Using the custom hook to load a script
	useScript("//code.tidio.co/hjkjuczfgylkeff4h7oaltgr8mcmv4ay.js");

	return null; // This component doesn't render anything, so we return null
};

class Business extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
		};
		this.openModal = this.openModal.bind(this);
	}

	openModal() {
		this.setState({ isOpen: true });
	}
	render() {
		var namesItemOne = [
			"Money Back Guarantee",
			"100% Transparency",
			"24 hours customer support",
			"Our team consists of well trained professionals",
		];

		const PostList = BlogContent.slice(0, 3);
		return (
			<Fragment>
				<Helmet pageTitle="Business" />

				<Header />
				{/* Start Slider Area   */}
				<div className="slider-wrapper">
					<div className="slider-activation">
						<ScriptLoader />
						<Slider className="rn-slick-dot dot-light" {...slideSlick}>
							{SlideList.map((value, index) => (
								<div
									className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
									key={index}
									data-black-overlay="5"
								>
									<div className="container">
										<div className="row">
											<div className="col-lg-12">
												<div className={`inner ${value.textPosition}`}>
													{value.category ? <span>{value.category}</span> : ""}
													{value.title ? (
														<h1 className="title">{value.title}</h1>
													) : (
														""
													)}
													{value.description ? (
														<p className="description">{value.description}</p>
													) : (
														""
													)}
													{value.buttonText ? (
														<div className="slide-btn">
															<a
																className="btn-default"
																href={`${value.buttonLink}`}
															>
																{value.buttonText}
															</a>
														</div>
													) : (
														""
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
				{/* End Slider Area   */}

				{/* Start Brand Area */}
				<div className="rn-brand-area bg_color--5 ptb--60">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-12 section-title text-center mb--30 mb_sm--0">
								<span className="subtitle">Scams We've Helped With.</span>
								<BrandTwo />
							</div>
						</div>
					</div>
				</div>
				{/* End Brand Area */}

				{/* Start Service Area  */}
				<div className="service-area creative-service-wrapper ptb--120 bg_color--1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center mb--30 mb_sm--0">
									<span className="subtitle">What we can do for you</span>
									<h2 className="title">Services we provide for you.</h2>
									<p className="description">
										Our team of experts works daily to secure refunds for those
										who have lost money to scammers. We help both businesses and
										individuals recover their hard-earned funds and strive to
										continuously improve our services.
									</p>
								</div>
							</div>
						</div>
						<div className="row creative-service">
							<div className="col-lg-12">
								<ServiceList
									item="6"
									column="col-lg-3 col-md-6 col-sm-6 col-12 text-left"
								/>
							</div>
						</div>
					</div>
				</div>
				{/* End Service Area  */}

				{/* Start About Area  */}
				<div className="rn-about-area ptb--120 bg_color--5">
					<div className="container">
						<div className="row row--35 align-items-center">
							<div className="col-lg-6">
								<div className="thumbnail">
									<iframe
										width="100%"
										height="415"
										src="https://www.youtube.com/embed/xrtTSLtUrhM"
										frameborder="0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
									></iframe>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="about-inner inner">
									<div className="section-title">
										<span className="subtitle">Our recovery process</span>
										<h2 className="title">How it works</h2>
										<p className="description">
											We want you to feel at ease knowing that we are fully
											committed to being transparent in our assistance to you
											during the recovery process. You can trust us to take all
											necessary steps to ensure your peace of mind and achieve
											the most favorable outcome.
										</p>
									</div>
									<div className="accordion-wrapper mt--30">
										<Accordion01 />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End About Area  */}

				{/* Start CounterUp Area */}
				<div className="rn-counterup-area ptb--120 bg_color--1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center">
									<span className="subtitle">Recoverysol</span>
									<h2 className="title">Our Company Growth</h2>
									<p className="description">Our growth for the year 2023.</p>
								</div>
							</div>
						</div>
						<CounterOne />
					</div>
				</div>
				{/* End CounterUp Area */}
				{/* Start About Area  */}
				<div className="rn-about-area ptb--120 bg_color--1">
					<div className="container">
						<div className="row row--35 align-items-center">
							<div className="col-lg-6 order-2 order-lg-1">
								<div className="about-inner inner">
									<div className="section-title">
										<span className="subtitle">Our about</span>
										<h2 className="title">About Recoverysol</h2>
										<p className="description">
											Recoverysol is a globally recognized firm that specializes
											in asset and crypto recovery services. Our exceptional
											success rate in the industry is a testament to our
											unwavering commitment to our clients. We take pride in our
											straightforward approach to dealing with scammers and
											other fraudulent activities. We only take on cases that
											have a high likelihood of a successful recovery. We value
											your time as much as we value our own, which is why we
											maintain complete transparency in our case evaluation. If
											we determine that a case is not feasible, we will inform
											you right away. If, however, we see the potential for
											complete or partial recovery, we will take immediate
											action
										</p>
									</div>
									<div className="mt--30">
										<h4>Why work with us.</h4>
										<ul className="list-style--1">
											{namesItemOne.map((name, index) => {
												return (
													<li key={index}>
														<FiCheck /> {name}
													</li>
												);
											})}
										</ul>
									</div>

									<div className="about-button mt--50">
										<a className="rn-button-style--2 btn-solid" href="/about">
											Learn more about us
										</a>
									</div>
								</div>
							</div>
							<div className="col-lg-6 order-1 order-lg-2">
								<div className="thumbnail position-relative">
									<img
										className="w-100"
										src="/assets/images/about/about-3.png"
										alt="About Images"
									/>
									<ModalVideo
										channel="youtube"
										isOpen={this.state.isOpen}
										videoId="ZZDI59uQ_Yc"
										onClose={() => this.setState({ isOpen: false })}
									/>
									<button
										className="video-popup position-top-center theme-color"
										onClick={this.openModal}
									>
										<span className="play-icon"></span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End About Area  */}

				{/* Start Testimonial Area */}
				<div className="rn-testimonial-area bg_color--1 ptb--120">
					<div className="container">
						<Testimonial />
					</div>
				</div>
				{/* End Testimonial Area */}

				{/* Start Blog Area */}
				<div className="rn-blog-area pt--120 pb--80 bg_color--5">
					<div className="container">
						<div className="row align-items-end">
							<div className="col-lg-12">
								<div className="section-title text-center">
									<span className="subtitle">Blog</span>
									<h2 className="title">Latest News</h2>
								</div>
							</div>
						</div>
						<div className="row mt--30">
							<BlogHome />
						</div>
					</div>
				</div>
				{/* End Blog Area */}

				{/* Start Footer Style  */}
				<Footer />
				{/* End Footer Style  */}

				{/* Start Back To Top */}
				{/* <div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div> */}
				{/* End Back To Top */}
			</Fragment>
		);
	}
}
export default Business;
