// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from "./component/PageScrollTop";
import Business from "./home/Business";

// Element Layout
import Service from "./elements/Service";

import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import Privacy from "./blocks/Columns";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Terms from "./blocks/Terms";
import Tracing from "./elements/Tracing";
import Recovery from "./elements/Recovery";
import Investigation from "./elements/Investigation";
import Analysis from "./elements/Analysis";

class Root extends Component {
	render() {
		return (
			<BrowserRouter basename={"/"}>
				<PageScrollTop>
					<Switch>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/`}
							component={Business}
						/>

						{/* Element Layot */}
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/service`}
							component={Service}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/tracing`}
							component={Tracing}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/recovery`}
							component={Recovery}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/investigation`}
							component={Investigation}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/analysis`}
							component={Analysis}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/contact`}
							component={Contact}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/about`}
							component={About}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/portfolio-details`}
							component={PortfolioDetails}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/blog`}
							component={Blog}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/blog/:id`}
							component={BlogDetails}
						/>
						

						{/* Blocks Elements  */}
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/counters`}
							component={Counters}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/testimonial`}
							component={Testimonial}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/clint-logo`}
							component={Brand}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/progressbar`}
							component={ProgressBar}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/contact-form`}
							component={ContactForm}
						/>

						<Route
							exact
							path={`${process.env.PUBLIC_URL}/privacy`}
							component={Privacy}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/terms`}
							component={Terms}
						/>
						<Route
							path={`${process.env.PUBLIC_URL}/404`}
							component={error404}
						/>
						<Route component={error404} />
					</Switch>
				</PageScrollTop>
			</BrowserRouter>
		);
	}
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
