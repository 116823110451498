import React, { useState, useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { FiClock, FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const BlogDetails = () => {
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();
	const mytitle = location.pathname.toString().substr(6);
	const [post, setPost] = useState({});
	useEffect(() => {
		axios
			.get(
				"https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@Recoverysol"
			)

			.then((data) => {
				// console.log(data.data)
				const res = data.data.items;
				setPost(res);
			})
			.catch((e) => {
				this.setState({ error: e.toJSON() });
				console.log(e);
			});
	}, []);
	const postFiltered =
		post.length >= 1 && post.filter((item) => item.title === mytitle);

	console.log(mytitle);
	const openModal = () => {
		setIsOpen(true);
	};

	return (
		<React.Fragment>
			<PageHelmet pageTitle="Recoverysol Blog" />
			<Header
				headertransparent="header--transparent"
				colorblack="color--black"
				logoname="logo.png"
			/>

			{/* Start Breadcrump Area */}
			<div
				className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27"
				data-black-overlay="7"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="blog-single-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									{post.length >= 1 && postFiltered[0].title}
								</h2>
								<ul className="blog-meta d-flex justify-content-center align-items-center">
									<li>
										<FiClock />
										{post.length >= 1 &&
											moment(postFiltered[0].pubDate).format("MMMM Do, YYYY")}
									</li>
									<li>
										<FiUser />
										{post.length >= 1 && postFiltered[0].author}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Blog Details */}
			<div className="rn-blog-details pt--110 pb--70 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="inner-wrapper">
								<div className="inner">
									<div
										dangerouslySetInnerHTML={{
											__html: post.length >= 1 && postFiltered[0].content,
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Blog Details */}

			{/* Start Back To Top */}
			{/* <div className="backto-top">
				<ScrollToTop showUnder={160}>
					<FiChevronUp />
				</ScrollToTop>
			</div> */}
			{/* End Back To Top */}

			<Footer />
		</React.Fragment>
	);
};

export default BlogDetails;
