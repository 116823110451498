import React, { Component, Fragment } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

class BLogList extends Component {
	state = {
		post: {},
	};

	mediumURL =
		"https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@Recoverysol";

	componentDidMount() {
		axios
			.get(this.mediumURL)

			.then((data) => {
				console.log(data.data);
				const res = data.data.items; //This is an array with the content. No feed, no info about author etc..
				this.setState({
					post: res,
				});
			})
			.catch((e) => {
				this.setState({ error: e.toJSON() });
				console.log(e);
			});
	}
	render() {
		return (
			<Fragment>
				<div className="row mt_dec--30">
					{this.state.post.length >= 1 &&
						this.state.post.map((item) => {
							const imgurl = item.description;
							const match =
								typeof imgurl === "string" &&
								imgurl.match(/<img[^>]+src="([^">]+)"/);
							const imageSrc = match ? match[1] : null;
							return (
								<div
									className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
									key={item.id}
								>
									<div className="im_box">
										<div className="thumbnail">
											<Link to="/blog-details">
												<img
													className="blog_width"
													src={imageSrc}
													alt="Blog Images"
												/>
											</Link>
										</div>
										<div className="content">
											<div className="inner">
												<div className="content_heading">
													<h4 className="title">
														<Link to="/blog-details">{item.title}</Link>
													</h4>
												</div>
												<div className="content_footer">
													<Link
														to={`/blog/${item.title}`}
														className="rn-btn btn-opacity"
													>
														Read More
													</Link>
												</div>
											</div>
											<Link
												className="transparent_link"
												to={`/blog/${item.title}`}
											></Link>
										</div>
									</div>
								</div>
							);
						})}
				</div>
			</Fragment>
		);
	}
}
export default BLogList;
