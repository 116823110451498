import React, { Component } from "react";
import { FaSearchengin } from "react-icons/fa";
import { IoIosLaptop } from "react-icons/io";
import { FcDataRecovery } from "react-icons/fc";
import { IoMdAnalytics } from "react-icons/io";
const ServiceList = [
	{
		icon: <FcDataRecovery />,
		title: "Asset & Fund Recovery",
		href: "/recovery",
		description:
			"We specialize in asset recovery, providing vital evidence for successful fund recovery.",
	},
	{
		icon: <FaSearchengin />,
		title: "Crypto Asset Tracing",
		href: "/tracing",
		description:
			"We trace crypto scam money trails & aid global recovery, bringing justice to victims.",
	},
	{
		icon: <IoIosLaptop />,
		title: "Private Investigation",
		href: "/investigation",
		description:
			"We conduct due diligence to identify risks & provide customized reports.",
	},
	{
		icon: <IoMdAnalytics />,
		title: "Invesment Fraud Analysis",
		href: "/analysis",
		description:
			"We investigate through surveillance, cybercrime methods, & profiling for legal action.",
	},
];

class ServiceThree extends Component {
	render() {
		const { column } = this.props;
		const ServiceContent = ServiceList.slice(0, this.props.item);

		return (
			<React.Fragment>
				<div className="row service-main-wrapper">
					{ServiceContent.map((val, i) => (
						<div className={`${column}`} key={i}>
							<a href={val.href}>
								<div className="service service__style--2 text-left bg-gray">
									<div className="icon">{val.icon}</div>
									<div className="content">
										<h3 className="title">{val.title}</h3>
										<p>{val.description}</p>
									</div>
								</div>
							</a>
						</div>
					))}
				</div>
			</React.Fragment>
		);
	}
}
export default ServiceThree;
