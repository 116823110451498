import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import { FaSearchengin } from "react-icons/fa";
import { IoIosLaptop } from "react-icons/io";
import { FcDataRecovery } from "react-icons/fc";
import { IoMdAnalytics } from "react-icons/io";

import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Testimonial from "./Testimonial";
import BrandTwo from "./BrandTwo";

const ServiceList = [
	{
		icon: <FcDataRecovery />,
		title: "Asset & Fund Recovery",
		href: "/recovery",
		description:
			"We specialize in asset recovery, providing vital evidence for successful fund recovery.",
	},
	{
		icon: <FaSearchengin />,
		title: "Crypto Asset Tracing",
		href: "/tracing",
		description:
			"We trace crypto scam money trails & aid global recovery, bringing justice to victims.",
	},
	{
		icon: <IoIosLaptop />,
		title: "Private Investigation",
		href: "/investigation",
		description:
			"We conduct due diligence to identify risks & provide customized reports.",
	},
	{
		icon: <IoMdAnalytics />,
		title: "Invesment Fraud Analysis",
		href: "/analysis",
		description:
			"We investigate through surveillance, cybercrime methods, & profiling for legal action.",
	},
];

class Service extends Component {
	render() {
		return (
			<React.Fragment>
				<PageHelmet pageTitle="Service" />
				<Header
					headertransparent="header--transparent"
					colorblack="color--black"
					logoname="logo.png"
				/>

				{/* Start Breadcrump Area */}
				<Breadcrumb title={"Service"} />
				{/* End Breadcrump Area */}

				{/* Start Service Area */}
				<div className="service-area ptb--120 bg_color--1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center mb--30">
									<span className="subtitle">Our Services</span>
									<h2>Services we offer</h2>
									<p>
										Bellow are the list of services we offer at Recoverysol.
									</p>
								</div>
							</div>
						</div>

						<div className="row service-main-wrapper">
							{ServiceList.map((val, i) => (
								<div
									className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
									key={i}
								>
									<a className="text-center" href={val.href}>
										<div className="service service__style--2">
											<div className="icon">{val.icon}</div>
											<div className="content">
												<h3 className="title">{val.title}</h3>
												<p>{val.description}</p>
											</div>
										</div>
									</a>
								</div>
							))}
						</div>
					</div>
				</div>
				{/* End Service Area */}
				{/* Start Testimonial Area */}
				<div className="rn-testimonial-area bg_color--1 ptb--120">
					<div className="container">
						<Testimonial />
					</div>
				</div>
				{/* End Testimonial Area */}

				{/* Start Brand Area */}
				<div className="rn-brand-area bg_color--5 ptb--60">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-12 section-title text-center mb--30 mb_sm--0">
								<span className="subtitle">Scams We've Helped With.</span>
								<BrandTwo />
							</div>
						</div>
					</div>
				</div>
				{/* End Brand Area */}
				{/* Start Back To Top */}
				{/* <div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div> */}
				{/* End Back To Top */}

				<Footer />
			</React.Fragment>
		);
	}
}
export default Service;
