import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Recovery extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
		};
		this.openModal = this.openModal.bind(this);
	}
	openModal() {
		this.setState({ isOpen: true });
	}
	render() {
		return (
			<React.Fragment>
				{/* Start Pagehelmet  */}
				<PageHelmet pageTitle="Service Details" />
				{/* End Pagehelmet  */}

				<Header
					headertransparent="header--transparent"
					colorblack="color--black"
					logoname="logo.png"
				/>

				{/* Start Breadcrump Area */}
				<div
					className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--4"
					data-black-overlay="5"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
									<h2 className="title theme-gradient">Asset Recovery</h2>
									<p>
										Our experts provide law enforcement agencies and law firms
										with the necessary tools and knowledge for a successful
										recovery. Trust us to help you recover funds lost through
										bank transfers or cryptocurrency transactions.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Breadcrump Area */}

				{/* Start Page Wrapper */}
				<div className="rn-service-details ptb--120 bg_color--1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="service-details-inner">
									<div className="inner">
										{/* Start Single Area */}
										<div className="row row--35 sercice-details-content align-items-center">
											<div className="service_link rn-contact-top-area ptb--20 bg_color--5 col-lg-4 col-12">
												<div className="section-title">
													<span className="subtitle">Our services</span>
												</div>
												<br />
												<p className="service_links">
													<a href="/recovery">Asset & Fund Recovery</a>
												</p>
												<p className="service_links">
													<a href="/tracing">Crypto Asset Tracing</a>
												</p>
												<p className="service_links">
													<a href="/investigation">Private Investigation</a>
												</p>
												<p className="service_links">
													<a href="/analysis">Invesment Fraud Analysis</a>
												</p>
											</div>
											<div className="col-lg-8 col-12">
												<br /> <br />
												<div className="details ">
													<h2 className="title">Asset Recovery</h2>
													<p>
														Asset recovery is a crucial process that ensures
														justice is served and that stolen assets are
														rightfully returned to their owners or countries. By
														recovering the proceeds of corruption that have been
														transferred abroad, we can put an end to the culture
														of impunity that allows corrupt individuals to
														profit from their wrongdoing. Let us work together
														to ensure that those who engage in corrupt
														activities are held accountable for their actions
														and that stolen assets are returned where they
														belong.
													</p>
													<p className="description">
														Our team of international banking experts has a
														top-notch record of assisting law enforcement and
														law firms in recovering funds that were sent through
														bank wire and cryptocurrency tracing methods. With
														our innovative fund tracing service and a network of
														lawyers worldwide, we can accurately trace the
														movement of funds and apply pressure on the
														beneficiary bank and company that received the
														funds. We have the expertise and experience to
														overcome unique challenges presented by each
														jurisdiction. Our investigations team, led by Roman
														Garcia, can trace and provide evidence for various
														cryptocurrencies, including Bitcoin and Ethereum. We
														track down the owners of the cryptocurrency by
														tracing it to the last wallet before being exchanged
														for fiat currency, thereby enabling the recovery of
														your funds.
													</p>
													<p className="description">
														We have a technical solution that allows us to trace
														the movement of cryptocurrencies. We can identify
														specific wallets, determine their location, and
														track the access of those who use them. It's
														important to note that not all cryptocurrencies are
														traceable. However, we can trace the most popular
														cryptocurrencies like Bitcoin and Ethereum, among
														others. Invest in cryptocurrencies with confidence,
														knowing that our technical solution can provide you
														with secure and transparent transactions.
													</p>
													<br />
													<h2 className="title">Asset Recovery Process</h2>
													<p>
														Our asset tracing and recovery process provides a
														comprehensive solution to safeguard your assets. We
														begin with a thorough data collection process,
														gathering all the necessary information related to
														the assets in question.
													</p>
													<p>
														Our advanced blockchain analysis techniques allow us
														to trace transactions and identify the movement of
														digital assets with precision, while our forensic
														investigation services help to uncover crucial
														evidence in cases involving illicit activities or
														fraudulent transactions.
													</p>
													<p>
														In cases where assets are lost or stolen, our
														tailored solutions for asset recovery, combined with
														our extensive network of industry contacts and
														expertise in legal frameworks, maximize the chances
														of successful recovery.
													</p>
													<p>
														Our continuous monitoring solutions are tailored to
														your specific needs, providing proactive detection
														of any suspicious activities related to your assets.
														Choose our asset tracing and recovery process to
														protect your assets and benefit from our expertise
														and commitment to excellence.
													</p>
													<br />
													<h2 className="title">Our Experties</h2>
													<p>
														Our team of cyber investigators, researchers and
														analysts has been tirelessly working for over three
														decades to track down and bring scammers to justice
														around the world. Our expertise covers a range of
														fraudulent activities, including romance fraud,
														identity theft, betting scams and Ponzi schemes.
													</p>
													<p>
														Our investigative prowess is unparalleled, and we
														are known for delivering results that maximize the
														likelihood of recovering your assets. We have
														connections on every continent and have experience
														working closely with state, federal and
														international law enforcement agencies. We also team
														up with local and foreign correspondent lawyers to
														trace money trails and reveal hidden assets in
														almost any country.
													</p>
													<p>
														Our dynamic techniques include leveraging direct
														access to local databases, expert witnesses in cyber
														forensics and cybercrime, and a network of
														confidential informants. We use a range of methods
														to conduct rigorous investigations into the most
														complex fraudulent operations in foreign countries.
														We know how important it is to have solid evidence,
														which is why we prepare a brief of evidence for use
														in criminal proceedings in the appropriate
														jurisdiction. Our evidence is ironclad and includes
														proof of fraud and the identities of those involved.
														This allows you to file complaints and take steps to
														recoup your money.
													</p>
													<p>
														Our investigators have provided crucial support to
														international law enforcement agencies in their
														efforts to hunt down organized cybercrime groups. We
														share critical evidence that has led to major police
														raids, arrests and prosecutions across the globe.
														Discretion is of the utmost importance to us. We
														handle your personal data with the utmost care and
														confidentiality, encrypting every piece of
														information we receive. Our dedicated investigators
														deliver asset recovery services with steadfast
														professionalism and sensitivity.
													</p>
													<p>
														At Recoverysol , we are dedicated to bringing
														scammers to justice and helping our clients recover
														their assets. With our unparalleled investigative
														expertise, you can trust us to deliver the results
														you need.
													</p>
												</div>
											</div>
										</div>
										{/* End Single Area */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Page Wrapper */}

				{/* Start Back To Top */}
				<div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div>
				{/* End Back To Top */}

				<Footer />
			</React.Fragment>
		);
	}
}
export default Recovery;
