import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
class Testimonial extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="row">
					<div className="col-lg-12">
						<Tabs>
							<div className="row align-items-center">
								<div className="col-lg-6">
									<TabPanel>
										<div className="rn-testimonial-content text-left">
											<div className="inner">
												<p>
													Recoverysol's asset and recovery services helped me
													reclaim my funds by tracking down the fraudsters
													involved in my investment scam. Their expertise in
													crypto asset tracing is truly exceptional.
												</p>
											</div>
											<div className="author-info">
												<h6>
													<span>Samantha Reynolds </span>
												</h6>
											</div>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="rn-testimonial-content text-left">
											<div className="inner">
												<p>
													Recoverysol helped me recover my lost crypto assets
													through their efficient asset tracing service. I am
													thankful for their prompt assistance in securing my
													assets.
												</p>
											</div>
											<div className="author-info">
												<h6>
													<span>Alice Johnson</span>
												</h6>
											</div>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="rn-testimonial-content text-left">
											<div className="inner">
												<p>
													Recoverysol provided exceptional services in asset and
													recovery, crypto asset tracing, and private
													investigation. Their team was thorough, professional,
													and efficient. I was impressed with the results they
													delivered. Highly recommend Recoverysol for all your
													investigative needs.
												</p>
											</div>
											<div className="author-info">
												<h6>
													<span>Michael Jow </span>
												</h6>
											</div>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="rn-testimonial-content text-left">
											<div className="inner">
												<p>
													Recoverysol's asset and recovery services are
													unparalleled. Their expertise in crypto asset tracing
													and private investigation is truly impressive. I
													highly recommend Recoverysol for anyone in need of
													top-notch investigative services.
												</p>
											</div>
											<div className="author-info">
												<h6>
													<span>Maria Thompson </span>
												</h6>
											</div>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="rn-testimonial-content text-left">
											<div className="inner">
												<p>
													Their expertise and professionalism exceeded my
													expectations. I highly recommend Recoverysol for all
													your investigative needs.
												</p>
											</div>
											<div className="author-info">
												<h6>
													<span>victoria Harris </span>
												</h6>
											</div>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="rn-testimonial-content text-left">
											<div className="inner">
												<p>
													They offer a comprehensive suite of services including
													asset and fund recovery, crypto asset tracing, private
													investigation, and investment fraud analysis. Whether
													you are an individual seeking to recover lost funds or
													a business looking to mitigate investment fraud risks,
													Recoverysol is the ultimate solution.
												</p>
											</div>
											<div className="author-info">
												<h6>
													<span>Sarah Elgort </span>
												</h6>
											</div>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="rn-testimonial-content text-left">
											<div className="inner">
												<p>
													They were able to recover my lost assets efficiently
													and effectively, providing me with a sense of relief
													and peace of mind. Their team demonstrated an
													impressive level of expertise and professionalism
													throughout the entire process, guiding me step by step
													and keeping me informed of every development.
												</p>
											</div>
											<div className="author-info">
												<h6>
													<span>Matt Williams</span>
												</h6>
											</div>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="rn-testimonial-content text-left">
											<div className="inner">
												<p>
													Recoverysol's expertise in crypto asset tracing was
													truly remarkable. With their assistance, I was able to
													trace and recover my lost crypto assets, which I
													thought were gone forever. They are truly the experts
													in their field and have proven to be trustworthy and
													reliable partners.
												</p>
											</div>
											<div className="author-info">
												<h6>
													<span>jonathan Collins </span>
												</h6>
											</div>
										</div>
									</TabPanel>
								</div>
								<div className="col-lg-6 mt_md--40 mt_sm--40">
									<TabList className="testimonial-thumb-wrapper">
										<Tab>
											<div className="testimonial-thumbnai">
												<div className="thumb">
													<img
														src="/assets/images/client/2.jpg"
														alt="Testimonial Images"
													/>
												</div>
											</div>
										</Tab>
										<Tab>
											<div className="testimonial-thumbnai">
												<div className="thumb">
													<img
														src="/assets/images/client/3.jpg"
														alt="Testimonial Images"
													/>
												</div>
											</div>
										</Tab>
										<Tab>
											<div className="testimonial-thumbnai">
												<div className="thumb">
													<img
														src="/assets/images/client/1.jpg"
														alt="Testimonial Images"
													/>
												</div>
											</div>
										</Tab>
										<Tab>
											<div className="testimonial-thumbnai">
												<div className="thumb">
													<img
														src="/assets/images/client/4.jpg"
														alt="Testimonial Images"
													/>
												</div>
											</div>
										</Tab>
										<Tab>
											<div className="testimonial-thumbnai">
												<div className="thumb">
													<img
														src="/assets/images/client/5.jpg"
														alt="Testimonial Images"
													/>
												</div>
											</div>
										</Tab>
										<Tab>
											<div className="testimonial-thumbnai">
												<div className="thumb">
													<img
														src="/assets/images/client/6.jpg"
														alt="Testimonial Images"
													/>
												</div>
											</div>
										</Tab>
										<Tab>
											<div className="testimonial-thumbnai">
												<div className="thumb">
													<img
														src="/assets/images/client/7.jpg"
														alt="Testimonial Images"
													/>
												</div>
											</div>
										</Tab>
										<Tab>
											<div className="testimonial-thumbnai">
												<div className="thumb">
													<img
														src="/assets/images/client/8.jpg"
														alt="Testimonial Images"
													/>
												</div>
											</div>
										</Tab>
									</TabList>
								</div>
							</div>
						</Tabs>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Testimonial;
